import styled from 'styled-components';
import {colors} from '../../../styles/variables';
import {media} from '../../../styles/utils';

export const StyledContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	//position: absolute;
	//top: 50%;
	//left: 50%;
	//transform: translate(-50%, -50%);
	width: ${(props) => (props.size ? `${props.size - 10}px` : `36px`)};
	height: ${(props) => (props.size ? `${props.size - 10}px` : `36px`)};
	margin: 0;

	${media.md`
		width: ${(props) => (props.size ? `${props.size}px` : `46px`)};
		height: ${(props) => (props.size ? `${props.size}px` : `46px`)};
	`};

	#progressInput {
		margin: 20px auto;
		width: 30%;
	}

	.circle-background,
	.circle-progress {
		fill: none;
	}

	.circle-background {
		stroke: transparent;
	}

	.circle-progress {
		stroke: ${colors.carrot};
		strokelinecap: round;
		stroke-linejoin: round;
	}

	.circle-text {
		font-size: 3em;
		font-weight: bold;
		fill: red;
		color: red;
	}
`;

export const CircleStyled = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&,
	svg {
		width: ${props => (props.size ? `${props.size - 10}px` : `36px`)};
		height: ${props => (props.size ? `${props.size - 10}px` : `36px`)};

		${media.md`
			width: ${props => (props.size ? `${props.size}px` : `46px`)};
			height: ${props => (props.size ? `${props.size}px` : `46px`)};
		`};
	}
`;
