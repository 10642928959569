import * as Constants from '../constants';

export const setDefault = () => ({
	type: Constants.SET_DEFAULT
});

export const setTest = () => ({
	type: Constants.SET_DEFAULT
});

export const getAllLikes = () => ({
	type: Constants.GET_ALL_LIKES
});

export const incrementLikes = () => ({
	type: Constants.INCREMENT_LIKES
});

export const postLike = id => ({
	type: Constants.POST_LIKE,
	payload: {
		id
	}
});

export const postUnlike = id => ({
	type: Constants.POST_UNLIKE,
	payload: {
		id
	}
});

export const getLike = id => ({
	type: Constants.GET_LIKE,
	payload: {
		id
	}
});

export const getAllBookmarks = userId => ({
	type: Constants.GET_ALL_BOOKMARKS,
	payload: {
		userId
	}
});

export const postBookmark = (userId, postId) => ({
	type: Constants.POST_BOOKMARK,
	payload: {
		userId,
		postId
	}
});

export const postUnbookmark = (userId, postId) => ({
	type: Constants.POST_UNBOOKMARK,
	payload: {
		userId,
		postId
	}
});

export const getBookmark = id => ({
	type: Constants.GET_BOOKMARK,
	payload: {
		id
	}
});

export const notifyUser = error => ({
	type: Constants.NOTIFY_USER,
	payload: {
		error
	}
});
