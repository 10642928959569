import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {StyledContainer, CircleStyled} from './styles';

class ProgressCircle extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const {sqSize, strokeWidth, percentage, children, visibility} = this.props;
		const radius = (sqSize - strokeWidth) / 2;
		const viewBox = `0 0 ${sqSize} ${sqSize}`;
		const dashArray = radius * Math.PI * 2;
		const dashOffset = dashArray - (dashArray * percentage) / 100;

		return (
			<StyledContainer size={sqSize}>
				<CircleStyled size={sqSize} style={{visibility: visibility ? 'visible' : 'visible'}}>
					<svg width={sqSize} height={sqSize} viewBox={viewBox}>
						<circle
							className="circle-background"
							cx={sqSize / 2}
							cy={sqSize / 2}
							r={radius}
							strokeWidth={`${strokeWidth}px`}
						/>
						<circle
							className="circle-progress"
							cx={sqSize / 2}
							cy={sqSize / 2}
							r={radius}
							strokeWidth={`${strokeWidth}px`}
							// Start progress marker at 12 O'Clock
							transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
							style={{
								strokeDasharray: dashArray,
								strokeDashoffset: dashOffset < 1 ? 0 : dashOffset
							}}
						/>
					</svg>
				</CircleStyled>
				{children}
			</StyledContainer>
		);
	}
}

export default ProgressCircle;

ProgressCircle.propTypes = {
	sqSize: PropTypes.string,
	percentage: PropTypes.number,
	strokeWidth: PropTypes.string,
	visibility: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired
};

ProgressCircle.defaultProps = {
	sqSize: 200,
	percentage: 25,
	strokeWidth: 10
};
